import React, { useEffect } from "react"
import Search from "../../components-pl/home/search"
import { isEmpty } from 'lodash'
import Layout from "../../components-pl/layout"
// import Carousel from "../../components-pl/home/carousel"
// import Hero from "../../images/hero1.jpg"
// import HeroMob from "../../images/hero-mob.jpg"

import Link from "gatsby-link"
// import ReactStars from "react-rating-stars-component"
import SEO from "../../components-pl/seo";
import Revs from "../../components-pl/reviews";
import SliderIn from "../../components-pl/slider2.js"
// import PricingTable from "../../components-pl/pricing-table"
import SliderPhoto from "../../components/slider";
import BlogModule from "../../components-pl/post-module";


const FrontPage = ( props ) => {

	useEffect(() => {
	console.log('Welcome to shroom drinks!')
	}, []);

	const {
		      pageContext: {
			      //page: {  seo, uri },
			      categories,
			      categoryName,
			      postSearchData: { products, options }
		      }
	      } = props;

	return (
		<Layout>
			{
				! isEmpty( props.pageContext ) ? (
					<>
					<SEO
						title={ 'Shroom - naturalne napoje energetyczne na bazie grzybów' }
						description={ 'Naturalne, energetyczne napoje z grzybami funcjonalnymi. Zawierają superfoods takie jak: soplówka jeżowata, cordyceps czy l-teanina.' }
						header={ { siteTitle: ' Sklep' } }
					/>

						{/* Hero */}
						<div id="hwrap" className="container-fluid mush" style={{
								padding:'0',
								minHeight: '600px',
								marginTop:'-30px',
								backgroundSize:'cover',
								backgroundPosition:'center',
								backgroundRepeat:'no-repeat',
						  }}>
						  <div id="hero" className="mask container-fluid" style={{backgroundColor: 'rgba(0, 0, 0, 0.33)',display:'flex',alignItems:'center',flexWrap:'wrap',width:'100%',minWidth:'100%',height:'100%',minHeight:'800px',justifyContent:'center'}}>
								<div className="col-8" style={{minHeight: '400px',display:'block',textAlign:'center'}}>
								<span className="spec-h"></span>
								<h1 className="text-white mob-hero spec-h" style={{textAlign:'center',fontSize:'5em',wordBreak:'break-word'}}>shroom drink</h1>
								<br />
								<h2 className="spec-h text-white" style={{textAlign:'center',fontSize:'1.2em'}}>Naturalne, zdrowe napoje energetyczne i uspokajającę z grzybami.<br /><br />Zawierają superfoods takie jak: soplówka jeżowata, cordyceps czy l-teanina.</h2>
								<p className="text-white" style={{textAlign:'center',margin:'auto',maxWidth:'500px',color:'#111'}}>
								<br /><br />
								<a className="btn btn-outline-light spec-h btn-lg" href="#archive" role="button" style={{fontVariant:'small-caps'}}>zobacz produkty</a>
								</p>
								<br />
									<p className="spec-h text-white" >Poznaj skarby grzybowego królestwa!</p>
									<div style={{padding:'2em',textAlign:'center',color:'white'}}>
									<a id="go" href="#mushroom-drinks" style={{fontSize:'15px'}}>⇩</a>
									</div>
								</div>
						  </div>
						</div>


						{/* Categories module */}
	          <div id="mushroom-drinks" style={{width:'100%',padding:'2.5em 0',backgroundColor:'#E8D3D0'}}>
			  <br />
	            <div className="row container-fluid" style={{margin:'auto'}}>
	            <div className="column col mx-auto" style={{ height: '750px', position: 'relative' }}>
	              <a href="#archive">
	                <div className="home-mod" style={{ position: 'relative', overflow: 'hidden', padding: '1em', margin: '1em', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
	                  {/* Overlay container */}
	                  <div style={{ position: 'absolute', top: '20px', left: 'auto', padding: '1em', color: '#1D1D1D', zIndex: 2, maxWidth:'362px' }}>
	                    <h2 className="spec-h home-m">Shroom drinks</h2>
	                    <p>Prawdopodobnie <span className="spec-h">#1 grzybowy</span> napój funkcjonalny w Europie.</p>
	                    <br />
	                    <p style={{textAlign:'center',maxWidth:'100%'}}><span className="btn">Odkryj więcej</span></p>
	                  </div>
	                  <video
	                    style={{
	                      height: '650px',
	                      boxShadow: '5px 5px 20px rgba(23,19,43,.2)',
	                      borderRadius: '25px',
	                      margin: 'auto',
	                      maxWidth: '100%',
	                      objectFit: 'cover',
	                    }}
											autoPlay="autoplay"
	                    loop="loop"
	                    muted
	                    defaultmuted="true"
	                    playsInline
	                    onContextMenu={()=>{return false}}
	                    loading="lazy"
	                    src="https://admin.shroom4you.com/wp-content/uploads/2024/01/ANIMACJA_11.mp4"
	                  ></video>
	                </div>
	              </a>
	            </div>
	              <div className="column col mx-auto mod-r" style={{height:'750px'}}>

	                <a href="#ubrania-i-akcesoria">
	                <div className="home-mod" style={{position: 'relative', overflow: 'hidden', padding: '1em', margin: '1em'}}>

	                {/* Overlay container */}
	                <div style={{ position: 'absolute', top: '20px', left: 'auto', padding: '1em', color: '#FEFEFE', zIndex: 2, width:'100%'}}>
	                  <h2 className="spec-h home-m">Akcesoria</h2>
	                  <p>Oryginalne akcesoria <span className="spec-h">shroom</span>. <br /> <b>Dla prawdziwych grzybiarzy i nie tylko</b>! </p>
	                  <br />
	                  <p style={{textAlign:'center'}}><span className="btn">Odkryj więcej</span></p>
	                </div>

	                  <img src="https://admin.shroom4you.com/wp-content/uploads/2024/01/acc.jpg"
	                    alt=""
	                    loading="lazy"
	                    style={{
	                       gridArea: "1/1",
	                       width:'100%',
	                       objectFit:'cover',
	                       minHeight:'300px',
	                       maxWidth: '100%',
	                       boxShadow:'5px 5px 20px rgba(23,19,43,.2)',
	                       borderRadius:'25px',
	                     }}
	                     />
	                </div>
	                </a>

	                <Link to="/pl/subskrypcje/">
	                  <div className="home-mod" style={{position: 'relative', overflow: 'hidden', padding: '1em', margin: '1em'}}>
	                  {/* Overlay container */}
	                  <div style={{ position: 'absolute', top: '20px', left: 'auto', padding: '1em', color: '#FEFEFE', zIndex: 2, width:'100%'}}>
	                    <h2 className="spec-h home-m">Shroomskrypcje</h2>
	                    <p>Nigdy więcej nie zabraknie Ci napojów &nbsp;<span className="spec-h">shroom</span>. <br />Pakiety subskrypcyjne!
	                    </p>
	                    <br />
	                    <p style={{textAlign:'center'}}><span className="btn">Odkryj więcej</span></p>
	                  </div>

	                    <img src="https://admin.shroom4you.com/wp-content/uploads/2024/01/shroomscriptions.jpg"
	                      alt=""
	                      loading='lazy'
	                      style={{
	                         gridArea: "1/1",
	                         width:'100%',
	                         objectFit:'cover',
	                         minHeight:'300px',
	                         maxWidth: '100%',
	                         boxShadow:'5px 5px 20px rgba(23,19,43,.2)',
	                         borderRadius:'25px',
	                       }}
	                       />
	                  </div>
	                </Link>

	                <div style={{marginTop:'30px'}}> </div>
	                </div>
	            </div>
	          </div>







	            <div className="container-fluid" style={{textAlign:'center',padding:'1.5em 15px',backgroundColor:'#FCFAF6'}}>
	            <h2 className="spec-h my-2"> Pierwszy grzybowy napój funkcjonalny w UE<br /> z grzybami medycznymi i adaptogenami.
	            <br />
	            <br />
	            <sub id="archive"> CZUJ SIĘ LEPIEJ Z KAŻDYM ŁYKIEM </sub> </h2>
	            </div>

							<Search
							products={ products }
							initialProducts={ products }
							engine={ options }
							category={ categoryName }
							categories={ categories }
						/>
					</>
				) : (
					<div>Coś poszło nie tak...</div>
				)
			}


			<Revs />
			<SliderPhoto />
			<div className="container-fuild" style={{alignItems:'center',justifyContent:'center',textAlign:'center',margin:'5em 0em',padding:'2em 1em'}}>
			<h2 className="spec-h">
			Dowiedz się więcej o naszych składnikach!
			</h2>
			<p style={{maxWidth:'650px',margin:'auto'}}>
			Napoje grzybowe to prawdziwe super pożywienie, a raczej super napoje! Sprawdź ich niesamowite składniki aktywne, które pomogą Ci wstać na nogi lub zrelaksować się.
			</p>
			<br /><br />
			<SliderIn />
			<br /><br /><br />
			<p style={{textAlign:'center'}}>
			<Link to="/pl/nasza-historia/" className="btn btn-outline-dark mush">Dowiedz się o nich więcej</Link>
			</p>
			</div>
      <BlogModule />
      <br />
			<p style={{textAlign:'center'}}>
			<a target="_blank" rel="noreferrer" href="https://shroom4you.com/pl/blog/" className="btn btn-outline-dark mush">Odwiedź bloga</a>
			</p>
      <br /><br />
		</Layout>
	)
};
export default FrontPage;
